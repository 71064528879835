/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
// import * as XLSX from "xlsx";
import { format } from "date-fns";
import CryptoJS from "crypto-js";
import ExcelJS from "exceljs";

import config from "../../config";

const { encryptionPassword, encryptionSecretKey, payPassword, paySecret } =
	config().secrets;

export const formatDate = (date) => {
	const d = new Date(date);
	if (Number.isNaN(d)) return "N/A";
	let month = `${d.getMonth() + 1}`;
	let day = `${d.getDate()}`;
	const year = d.getFullYear();

	if (month.length < 2) month = `0${month}`;
	if (day.length < 2) day = `0${day}`;

	return [day, month, year].join("-");
};

export const formatNumber = (num, point = 2) => {
	const formats = String(num);
	if (Number.isNaN(num)) return 0;
	const toFixed = Number.parseFloat(num).toFixed(point);
	const second = String(toFixed).split(".")[1];
	const first = formats.split(".")[0];
	const third = new Intl.NumberFormat().format(Number(first));
	return `${third}.${second}`;
};

export const convertDateToIso = (dateStr) => {
	const date = new Date(dateStr);
	return date.toISOString();
};

export const isEmpty = (obj) => {
	if (obj && Object.entries(obj).length === 0) return true;
	return false;
};

export const isObjectEmpty = (obj) => {
	return Object.keys(obj).length === 0;
};

export const sum = (obj, prop) => {
	return obj.reduce((item, acc) => item + Number(acc[prop]), 0);
};

export const getDateXDaysAgo = (numOfDays, date = new Date()) => {
	const daysAgo = new Date(date.getTime());

	daysAgo.setDate(date.getDate() - numOfDays);

	return daysAgo;
};

export const getDateXDaysFromNow = (numOfDays, date = new Date()) => {
	const daysAgo = new Date(date.getTime());

	daysAgo.setDate(date.getDate() + numOfDays);

	return daysAgo;
};
export const addMinutes = (minutes, date = new Date()) => {
	return new Date(date.getTime() + minutes * 60000);
};

export const addSeconds = (seconds, date = new Date()) => {
	return new Date(date.getTime() + seconds * 1000);
};

export const numberFormatter = (number, float = 2) => {
	if (number) {
		if (Number.isInteger(Number(number))) {
			return Number(number).toLocaleString("en-US");
		}

		if (!Number.isInteger(Number(number))) {
			return Number(number).toLocaleString("en-US", {
				maximumFractionDigits: float,
				minimumFractionDigits: float,
			});
		}
		return number;
	}
	return "0";
};

// export const downloadExcel = (dataToExport, title, filename) => {
// 	const newData = dataToExport.map((row) => {
// 		const element = row;
// 		delete element.tableData;
// 		return element;
// 	});
// 	const workSheet = XLSX.utils.json_to_sheet(newData);
// 	const workBook = XLSX.utils.book_new();
// 	XLSX.utils.book_append_sheet(workBook, workSheet, title);
// 	//  Buffer
// 	// eslint-disable-next-line
// 	const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
// 	//  Binary string
// 	XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
// 	//  Download
// 	XLSX.writeFile(workBook, `${filename}.xlsx`);
// };

export const discountCalculator = (originalPrice, discountPrice) => {
	const discount =
		(Number(originalPrice) - Number(discountPrice)) / Number(originalPrice);
	return discount * 100;
};

// export const dateFormatter = (str) => {
// 	if (str) {
// 		try {
// 			const dateReverse = str.split(" ")[0];
// 			const dateArray = dateReverse.split("-");
// 			return format(
// 				new Date(dateArray[0], Number(dateArray[1]) - 1, dateArray[2]),
// 				"do MMM yyyy"
// 			);
// 		} catch (error) {
// 			return "";
// 		}
// 	}
// 	return "";
// };

// export const dateTimeFormatter = (dateStr) => {
// 	if (dateStr) {
// 		const dateReverse = dateStr.split(" ")[0];
// 		const dateArray = dateReverse.split("-");
// 		const timeArray = dateStr.split(" ")[1].split(":");
// 		return format(
// 			new Date(
// 				dateArray[0],
// 				Number(dateArray[1]) - 1,
// 				dateArray[2],
// 				timeArray[0],
// 				timeArray[1],
// 				timeArray[2]
// 			),
// 			"do MMM yyyy  h:mm a"
// 		);
// 	}
// 	return dateStr;
// };

export function encrypt(payload) {
	const stringifiedPayload = JSON.stringify(payload);

	const password = "ABCDEF";
	const secretKey = "APEX";

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 256 / 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	);

	const keyWords = hash.words.slice(0, 6);
	const ivWords = hash.words.slice(6, 8);

	const key = CryptoJS.lib.WordArray.create(keyWords);
	const iv = CryptoJS.lib.WordArray.create(ivWords);

	const encrypted = CryptoJS.TripleDES.encrypt(
		CryptoJS.enc.Utf16LE.parse(stringifiedPayload),
		key,
		{
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}
	);

	return encrypted.toString();
}

export function decrypt(encryptedPayload) {
	const password = "ABCDEF";
	const secretKey = "APEX";

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 256 / 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	);

	const keyWords = hash.words.slice(0, 6);
	const ivWords = hash.words.slice(6, 8);

	const key = CryptoJS.lib.WordArray.create(keyWords);
	const iv = CryptoJS.lib.WordArray.create(ivWords);

	const decrypted = CryptoJS.TripleDES.decrypt(
		{
			ciphertext: CryptoJS.enc.Base64.parse(encryptedPayload),
		},
		key,
		{
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}
	);

	const decryptedStr = decrypted.toString(CryptoJS.enc.Utf16LE);

	return JSON.parse(decryptedStr);
}

// export function encryption(payload) {
// 	const password = "ABCDEF";
// 	const secretKey = "APEX";

// 	const key = CryptoJS.PBKDF2(password, CryptoJS.enc.Utf16LE.parse(secretKey), {
// 		keySize: 256 / 8,
// 		iterations: 1000,
// 		hasher: CryptoJS.algo.SHA1,
// 	}).toString(CryptoJS.enc.Hex);
// 	// .substring(0, 48);

// 	// const iv = CryptoJS.enc.Hex.parse(key.substring(200, 256));
// 	// const key1 = CryptoJS.enc.Hex.parse(key.substring(0, 200));
// 	const iv = key.substring(200, 256);
// 	const key1 = key.substring(0, 200);

// 	const key = CryptoJS.lib.WordArray.create(keyWords);
// 	const iv = CryptoJS.lib.WordArray.create(ivWords);

// 	const encryptionOptions = {
// 		iv: iv,
// 		mode: CryptoJS.mode.CBC,
// 		padding: CryptoJS.pad.Pkcs7,
// 	};

// 	console.log({
// 		key1,
// 		iv,
// 		key,
// 	});

// 	const encrypted = CryptoJS.AES.encrypt(
// 		CryptoJS.enc.Utf16LE.parse(JSON.stringify(payload)),
// 		key1,
// 		encryptionOptions
// 	);

// 	return { code: 200, data: encrypted.toString() };
// }]

export function encryption(payload) {
	const stringifiedPayload = JSON.stringify(payload);
	const password = encryptionPassword;
	const secretKey = encryptionSecretKey;

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	).toString(CryptoJS.enc.Hex);

	const key = hash.substring(0, 64);
	const iv = hash.substring(0, 42);

	const encryptionOptions = {
		iv: CryptoJS.enc.Hex.parse(iv),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	};

	const encrypted = CryptoJS.AES.encrypt(
		CryptoJS.enc.Utf16LE.parse(stringifiedPayload),
		CryptoJS.enc.Hex.parse(key),
		encryptionOptions
	);

	return encrypted.toString();
}

export function decryption(encryptedData) {
	const password = encryptionPassword;
	const secretKey = encryptionSecretKey;

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 256 / 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	).toString(CryptoJS.enc.Hex);

	const key = hash.substring(0, 64);
	const iv = hash.substring(0, 42);

	const encryptionOptions = {
		iv: CryptoJS.enc.Hex.parse(iv),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	};

	const decrypted = CryptoJS.AES.decrypt(
		encryptedData,
		CryptoJS.enc.Hex.parse(key),
		encryptionOptions
	);
	const stringifiedDecrypted = CryptoJS.enc.Utf16LE.stringify(decrypted);
	// return JSON.parse(CryptoJS.enc.Utf16LE.stringify(decrypted));
	return JSON.parse(stringifiedDecrypted.trim(""));
}

export const encryptString = (dataString) => {
	const encryptedString = CryptoJS.AES.encrypt(
		dataString,
		encryptionSecretKey
	).toString();
	return encryptedString;
};

// Decryption function
export const decryptString = (encryptedString) => {
	const decryptedBytes = CryptoJS.AES.decrypt(
		encryptedString,
		encryptionSecretKey
	);
	const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
	return decryptedString;
};

export function payEncryption(payload) {
	try {
		const stringifiedPayload = JSON.stringify(payload);
		const password = payPassword;
		const secretKey = paySecret;

		const hash = CryptoJS.PBKDF2(
			password,
			CryptoJS.enc.Utf16LE.parse(secretKey),
			{
				keySize: 32,
				iterations: 1000,
				hasher: CryptoJS.algo.SHA1,
			}
		).toString(CryptoJS.enc.Hex);

		const key = hash.substring(0, 64);
		const iv = hash.substring(0, 42);

		const encryptionOptions = {
			iv: CryptoJS.enc.Hex.parse(iv),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		};

		const encrypted = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf16LE.parse(stringifiedPayload),
			CryptoJS.enc.Hex.parse(key),
			encryptionOptions
		);

		return encrypted.toString();
	} catch (error) {
		console.error(error);
		return "";
	}
}

export function payDecryption(encryptedData) {
	try {
		const password = payPassword;
		const secretKey = paySecret;

		const hash = CryptoJS.PBKDF2(
			password,
			CryptoJS.enc.Utf16LE.parse(secretKey),
			{
				keySize: 256 / 32,
				iterations: 1000,
				hasher: CryptoJS.algo.SHA1,
			}
		).toString(CryptoJS.enc.Hex);

		const key = hash.substring(0, 64);
		const iv = hash.substring(0, 42);

		const encryptionOptions = {
			iv: CryptoJS.enc.Hex.parse(iv),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		};

		const decrypted = CryptoJS.AES.decrypt(
			encryptedData,
			CryptoJS.enc.Hex.parse(key),
			encryptionOptions
		);
		const stringifiedDecrypted = CryptoJS.enc.Utf16LE.stringify(decrypted);
		// return JSON.parse(CryptoJS.enc.Utf16LE.stringify(decrypted));
		return JSON.parse(stringifiedDecrypted.trim(""));
	} catch (error) {
		console.error(error);
		return {};
	}
}

export const hashString = (dataString) => {
	const hashedString = CryptoJS.MD5(dataString).toString();
	return hashedString;
};

export const shaHashString = (dataString) => {
	const sha512Hash = CryptoJS.SHA512(dataString);
	// Convert the hash to a hexadecimal string
	const hexHash = sha512Hash.toString(CryptoJS.enc.Hex);

	return hexHash;
};

export const dateFormatter = (str) => {
	if (str) {
		try {
			const dateReverse = str.split(" ")[0];
			const dateArray = dateReverse.split("-");
			return format(
				new Date(dateArray[0], Number(dateArray[1]) - 1, dateArray[2]),
				"do MMM yyyy"
			);
		} catch (error) {
			return "";
		}
	}
	return "";
};

export const generateRandomString = (length) => {
	const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
	let randomString = "";

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomString += characters.charAt(randomIndex);
	}

	return randomString;
};

export const newDateFormatter = (str, formatType = "do MMM yyyy") => {
	if (str) {
		return format(new Date(str), formatType);
	}
	return "";
};

export const formatNumberToDecimalPlace = (number, decimalPlaces) => {
	// Check if the provided number is not a valid number
	if (Number.isNaN(number)) {
		return "0";
	}

	// Round the number to the specified decimal places
	const roundedNumber = Number(number).toFixed(decimalPlaces);

	// Convert the rounded number to a string
	return Number(roundedNumber).toLocaleString("en-US", {
		maximumFractionDigits: decimalPlaces,
		minimumFractionDigits: decimalPlaces,
	});
};

export const downloadExcelExcelJs = (dataToExport, title, filename) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(title);

	// Define column headers from the first object in the array
	const columnHeaders = Object.keys(dataToExport[0]);
	worksheet.addRow(columnHeaders);

	// Set column widths
	worksheet.columns = columnHeaders.map(() => ({ width: 15 }));

	// Add data rows to the worksheet
	dataToExport.forEach((dataRow) => {
		worksheet.addRow(Object.values(dataRow));
	});

	// Generate Excel file
	workbook.xlsx
		.writeBuffer()
		.then((buffer) => {
			const blob = new Blob([buffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `${filename}.xlsx`;
			a.click();
			window.URL.revokeObjectURL(url);
		})
		// eslint-disable-next-line no-console
		.catch((error) => console.error("Error generating Excel file:", error));
};
export const downloadEventReportExcel = (dataToExport, title, filename) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(title);

	// Add the summary in bold font
	worksheet.addRow(["Summary"]).font = {
		bold: true,
		size: 14,
	};

	for (const key in dataToExport?.data) {
		const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
		worksheet.addRow([capitalizedKey, dataToExport?.data[key]]);
	}

	// Add an empty row
	worksheet.addRow([]);

	// Add the "Agents" header in bold font

	worksheet.addRow(["Agents"]).font = {
		bold: true,
		size: 14,
	};

	// Add the headers for agents in bold font
	worksheet.addRow(["S/N", "Agent Name", "Number of Guests Processed"]).font = {
		bold: true,
		size: 14,
	};

	// Add the agents data
	dataToExport?.agents.forEach((agent, index) => {
		worksheet.addRow([
			index + 1,
			agent.agentName,
			formatNumberToDecimalPlace(agent.Attendees),
		]);
	});

	// Add an empty row
	worksheet.addRow([]);

	// Add the "Guest List" header in bold font
	worksheet.addRow(["Guest List"]).font = {
		bold: true,
		size: 14,
	};

	worksheet.addRow([
		"S/N",
		"Name",
		"Email",
		"Category",
		"Agent",
		"Number of Guests",
	]).font = {
		bold: true,
		size: 14,
	};

	dataToExport?.attendees.forEach((attendee, index) => {
		worksheet.addRow([
			index + 1,
			attendee.fullName,
			attendee.emailAddress,
			attendee.ticketClass,
			attendee.agentName,
			formatNumberToDecimalPlace(attendee.guest),
		]);
	});

	// Set column widths
	worksheet.columns = [
		{ header: "", key: "", width: 30 },
		{ header: "", key: "", width: 50 },
		{ header: "", key: "", width: 50 },
		{ header: "", key: "", width: 50 },
		{ header: "", key: "", width: 50 },
		{ header: "", key: "", width: 50 },
	];
	// Generate Excel file
	workbook.xlsx
		.writeBuffer()
		.then((buffer) => {
			const blob = new Blob([buffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `${filename}.xlsx`;
			a.click();
			window.URL.revokeObjectURL(url);
		})
		// eslint-disable-next-line no-console
		.catch((error) => console.error("Error generating Excel file:", error));
};

export const generateFilename = (username) => {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, "0");
	const day = String(today.getDate()).padStart(2, "0");
	const formattedDate = `${year}${month}${day}`;

	const randomNumber = Math.floor(Math.random() * 900) + 100;

	const filename = `account_statement_${username}_${formattedDate}_${randomNumber}`;
	return filename;
};

const fetchImageAsBase64 = async (url) => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = function () {
			const reader = new FileReader();
			reader.onloadend = function () {
				resolve(reader.result.split(",")[1]);
			};
			reader.onerror = function (error) {
				reject(error);
			};
			reader.readAsDataURL(xhr.response);
		};
		xhr.onerror = function (error) {
			reject(error);
		};
		xhr.open("GET", url);
		xhr.responseType = "blob";
		xhr.send();
	});
};

export const downloadAccountStatementExcel = async (data, username) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet("Statement of Account");

	const logoUrl = data?.logo;

	try {
		const logoBase64 = await fetchImageAsBase64(logoUrl);
		const logoId = workbook.addImage({
			base64: logoBase64,
			extension: "png",
		});

		worksheet.addImage(logoId, {
			tl: { col: 0, row: 0 },
			ext: { width: 180, height: 180 },
		});
	} catch (error) {
		console.error("Error adding image to Excel file:", error);
	}

	// Adjust header details to start below the image
	const headerDetails = [
		{ label: "Full Name:", value: data.info.fullName },
		{ label: "Address:", value: data.info.address },
		{
			label: "Total Credit:",
			value: `₦ ${formatNumberToDecimalPlace(data.total_credit.balance, 2)}`,
		},
		{
			label: "Total Debit:",
			value: `₦ ${formatNumberToDecimalPlace(
				Math.abs(data.total_debit.balance),
				2
			)}`,
		},
		{
			label: "Current Balance:",
			value: `₦ ${formatNumberToDecimalPlace(Math.abs(data.balance), 2)}`,
		},
	];

	const startRow = 12;
	headerDetails.forEach((detail, index) => {
		const currentRow = startRow + index;
		worksheet.mergeCells(`A${currentRow}`, `B${currentRow}`);
		worksheet.getCell(`A${currentRow}`).value = detail.label;
		worksheet.getCell(`C${currentRow}`).value = detail.value;
		worksheet.getColumn("C").width = 30;
	});

	worksheet.addRow([]);

	// Add table headers
	const headerRow = worksheet.addRow([]);
	headerRow.getCell(1).value = "S/N";
	headerRow.getCell(2).value = "Date";
	headerRow.getCell(3).value = "Narration";
	headerRow.getCell(4).value = "Credit (₦)";
	headerRow.getCell(5).value = "Debit (₦)";

	headerRow.eachCell((cell) => {
		cell.font = { bold: true };
		cell.alignment = { vertical: "middle", horizontal: "center" };
		cell.border = { bottom: { style: "thin" } };
	});

	// Add transaction data starting from the next row
	data.data.forEach((transaction, index) => {
		const date = new Date(transaction.createdDate).toLocaleDateString("en-GB");
		const debit =
			transaction.transactionAmount < 0
				? `(${formatNumberToDecimalPlace(
						Math.abs(transaction.transactionAmount),
						2
				  )})`
				: "";
		const credit =
			transaction.transactionAmount > 0
				? formatNumberToDecimalPlace(transaction.transactionAmount, 2)
				: "";

		const row = worksheet.addRow([
			index + 1,
			date,
			transaction.narration,
			credit,
			debit,
		]);
		row.eachCell((cell) => {
			cell.alignment = { vertical: "middle", horizontal: "left" };
		});
	});

	// Auto resize columns based on content length
	worksheet.columns.forEach((column) => {
		let maxLength = 0;
		column.eachCell({ includeEmpty: true }, (cell) => {
			const cellValue = cell.value ? cell.value.toString() : "";
			maxLength = Math.max(maxLength, cellValue.length);
		});
		column.width = maxLength < 10 ? 10 : maxLength + 2; // Set minimum width
	});

	// Save the workbook
	try {
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = `${generateFilename(username)}.xlsx`;
		a.click();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error("Error generating Excel file:", error);
	}
};

export const parseJSONObjectFromLocalStorage = (key) => {
	try {
		return JSON.parse(localStorage.getItem(key));
	} catch (error) {
		return null;
	}
};

export const getColorBasedOnSign = (number) => {
	return Number(number) >= 0 ? "green" : "red";
};
